import React, { useState, useEffect, useContext, useMemo } from "react"
import { AppContext } from "../context/context"
import ReviewImage from "./ReviewImage"
import { shuffle } from "../../assets/js/helper"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import GoogleRating from "../google-rating-banner/GoogleRating"
import Quote from "../../assets/images/quote-left.png"

const SingleReview = ({ reviewImages, reviews }) => {
  const data = useStaticQuery(getLogo)

  const {
    size,
    isPRClientAndJQueryScriptsLoaded,
    loadPestRoutesClient,
    handleLoadingPRClientAndJQueryScripts,
  } = useContext(AppContext)

  const [singleReview, setSingleReview] = useState(null)
  const [stars, setStars] = useState("★★★★★")
  const { allKontentItemSettings } = data
  const { google_rating_banner } = allKontentItemSettings.nodes[0].elements

  // memoized the review image to prevent image flickering and function to re-run everytime the
  // component rerenders
  const randomReviewImage = useMemo(() => {
    const image = shuffle(reviewImages.value)
    return image
  }, [reviewImages])

  const googleRatingBanner = () => {
    if (google_rating_banner.value[0]) {
      return (
        <section>
          <GoogleRating
            url={google_rating_banner.value[0].elements.google_url.value}
          />
        </section>
      )
    }
  }

  /* eslint-disable */
  const getPestroutesClientReviews = async () => {
    if (typeof PestroutesClient !== "object") {
      return setTimeout(getPestroutesClientReviews, 100)
    }
    const result = await PestroutesClient.getReviews({
      favorable: 1,
      minRating: 4,
      limit: 15,
      randomize: true,
    })
    //remove reviews containing profanities
    const filteredReviews = result.reviews.filter(
      review => !review.profane && review.feedback.length > 10
    )

    //referenced randomized review to be used in the for loop
    const randomSingleReview = shuffle(filteredReviews)

    let reviewStars = ""
    for (let i = 0; i < randomSingleReview.rating; i++) {
      reviewStars += "★"
    }

    setSingleReview(randomSingleReview)
    setStars(reviewStars)
  }

  /* eslint-disable */
  useEffect(() => {
    let mounted = true

    // avoids loading jquery before the LCP is done
    if (typeof PestroutesClient !== "object") {
      setTimeout(() => {
        if (mounted) {
          loadPestRoutesClient()
          handleLoadingPRClientAndJQueryScripts(true)
        }
      }, 4000)
    } else {
      handleLoadingPRClientAndJQueryScripts(true)
    }

    return () => {
      mounted = false
    }
  }, [])

  /* eslint-disable */
  useEffect(() => {
    if (!isPRClientAndJQueryScriptsLoaded) return
    getPestroutesClientReviews()
  }, [isPRClientAndJQueryScriptsLoaded])

  return (
    <>
      {size >= 1600 && googleRatingBanner()}
      {singleReview && (
        <div className="singleReview_container container">
          <div className="singleReview_info">
            <h2>Customer Reviews</h2>
            {size < 1600 && googleRatingBanner()}
            <div className="divider">
              <div className="quote">
                <img src={Quote} alt="quote" />
              </div>
            </div>
            <div className="review_quote">{singleReview.feedback}</div>
            <div className="review_details">
              <div>
                <strong>{singleReview.reviewer}</strong> <span>|</span>
              </div>
              <div>
                {singleReview.city}, {singleReview.state} <span>|</span>
              </div>
              <div>
                <Link to="/reviews"> Read Reviews </Link>
              </div>
            </div>
            <div>
              <div className="review_stars">{stars}</div>
            </div>
          </div>

          <ReviewImage image={randomReviewImage} />
        </div>
      )}
    </>
  )
}

const getLogo = graphql`
  {
    allKontentItemSettings {
      nodes {
        elements {
          google_rating_banner {
            value {
              ... on kontent_item_google_rating_banner {
                id
                elements {
                  google_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SingleReview
