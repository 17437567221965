import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Bgimg from "gatsby-background-image"
import styles from "./cta-banner.module.scss"

const CTABanner = ({
  background,
  bgDescription,
  heading,
  description,
  buttons,
  phone,
  meta,
}) => {
  let location

  if (meta) {
    location = meta.breadcrumb.location
  } else {
    location = "/"
  }

  if (background) {
    return (
      <Bgimg
        fluid={background}
        className={styles.ctaBannerBg}
        alt={bgDescription}
      >
        <div className="container">
          <h2 className={styles.heading}>{heading}</h2>
          <a href={`tel:${phone}`} className={styles.phone}>
            {phone}
          </a>
          <p className={styles.description}>{description}</p>

          <div className={`flex-column-center ${styles.buttonContainer}`}>
            {buttons.value.map(({ id, elements }) => {
              const { button_text, linked_page, anchor_reference } = elements
              if (linked_page) {
                const { slug } = linked_page.value[0].elements
                return (
                  <Link
                    key={id}
                    to={`/${slug.value}`}
                    className="white-accent-ghost-button"
                  >
                    {button_text.value}
                  </Link>
                )
              } else {
                return (
                  <AnchorLink
                    key={id}
                    to={`${location}#${anchor_reference.value[0].name ? anchor_reference.value[0].name : 'form-banner'}`}
                    className="white-accent-ghost-button"
                  >
                    {button_text.value}
                  </AnchorLink>
                )
              }
            })}
          </div>
        </div>
      </Bgimg>
    )
  } else {
    return (
      <div className={styles.ctaBannerBg}>
        <div className="container">
          <h2 className={styles.heading}>{heading}</h2>
          <a href={`tel:${phone}`} className={styles.phone}>
            {phone}
          </a>
          <p className={styles.description}>{description}</p>

          <div className={`flex-column-center ${styles.buttonContainer}`}>
            {buttons.value.map(({ id, elements }) => {
              const { button_text, linked_page, anchor_reference } = elements
              if (linked_page) {
                const { slug } = linked_page.value[0].elements
                return (
                  <Link
                    key={id}
                    to={`/${slug.value}`}
                    className="white-accent-ghost-button"
                  >
                    {button_text.value}
                  </Link>
                )
              } else {
                return (
                  <AnchorLink
                    key={id}
                    to={`${location}#${anchor_reference.value[0].name}`}
                    className="white-accent-ghost-button"
                  >
                    {button_text.value}
                  </AnchorLink>
                )
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default CTABanner
